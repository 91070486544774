import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Persist } from 'formik-persist'
import * as Yup from 'yup'

const Fieldset = ({ name, label, ...rest }) => (
    <React.Fragment>
        <label htmlFor={name}>{label}</label>
        <Field id={name} name={name} {...rest} />
    </React.Fragment>
);

const FormikForm = ({ values }) => {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return <div className='container mx-auto my-12'>
        <Formik
            initialValues={{
                name: "",
                email: "",
                phone: "",
                reference: "",
                newsletter: false,
                picked: "one",
                checked: "",
            }}
            validationSchema={Yup.object({
                name: Yup.string().required("Required"),
                email: Yup.string().email("Invalid email address").required("Required"),
                phone: Yup.string().matches(phoneRegExp, "Invalid phone number").required("Required"),
                reference: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    // const myUrl = "http://localhost:1337/api/contact-forms";
                    const myUrl = "http://localhost:8055/items/contact";
                    fetch(myUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        // body: JSON.stringify({ data: values })
                        body: JSON.stringify(values)
                    }).then(function (response) {
                        console.log(response.status);
                        // success 
                        if (response.status >= 200 && response.status < 300) {
                            console.log(JSON.stringify({ data: values }, null, 2));
                            setSubmitting(false);
                            resetForm();
                            navigate("/login/");
                        }
                        // fail 
                        else {
                            alert("Error code " + response.status);
                        }
                    });
                }, 400);
            }}

        >
            {({ values, isSubmitting }) => (
                <Form>

                    <div className="w-1/2 space-y-4">
                        {/* name  */}
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="name" className="">Your Name</label>
                                <ErrorMessage className="" name="name">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                            </div>
                            <Field name="name" type="text" className="" />
                        </div>
                        {/* email  */}
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="email" className="">Email Address</label>
                                <ErrorMessage name="email">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                            </div>
                            <Field name="email" type="email" className="" />
                        </div>
                        {/* phone  */}
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="phone" className="">Phone Number</label>
                                <ErrorMessage name="phone">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                            </div>
                            <Field name="phone" type="text" className="" />
                        </div>
                        {/* reference  */}
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="reference" className="">How did you hear about us?</label>
                                <ErrorMessage name="reference">{msg => <span className="form-error">{msg}</span>}</ErrorMessage>
                            </div>
                            <Fieldset name="reference" as="select">
                                <option value="">Select an option</option>
                                <option value="Friend/Family">Friend/Family</option>
                                <option value="Financial Professional">Financial Professional</option>
                                <option value="Web Search">Web Search</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Other">Other</option>
                            </Fieldset>
                        </div>
                        {/* radio cards */}
                        <div role="group" aria-labelledby="my-radio-group" className="flex flex-row space-x-4">
                            <label className="grow">
                                <Field type="radio" name="picked" value="One" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">One</div>
                            </label>
                            <label className="grow">
                                <Field type="radio" name="picked" value="Two" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">Two</div>
                            </label>
                            <label className="grow">
                                <Field type="radio" name="picked" value="Three" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">Three</div>
                            </label>
                        </div>
                        {/* checkbox cards */}
                        <div role="group" aria-labelledby="checkbox-group" className="flex flex-row space-x-4">
                            <label className="grow">
                                <Field type="checkbox" name="checked" value="One" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">One</div>
                            </label>
                            <label className="grow">
                                <Field type="checkbox" name="checked" value="Two" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">Two</div>
                            </label>
                            <label className="grow">
                                <Field type="checkbox" name="checked" value="Three" className="hidden peer" />
                                <div className="w-full py-3 border text-center rounded bg-white peer-checked:bg-blue-50 border-gray-300 cursor-pointer peer-checked:border-blue-500 peer-checked:border-2">Three</div>
                            </label>
                        </div>
                        {/* newsletter checkbox  */}
                        <div className="">
                            <label className="flex flex-row space-x-2 text-lg">
                                <Field type="checkbox" name="newsletter" className="h-6 w-6" />
                                <span className='ml-2'>Subscribe to our Newsletter</span>
                            </label>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="button mt-2 grow-0 flex-none">Submit</button>
                    </div>

                    <pre className='mt-8'>{JSON.stringify(values, null, 2)}</pre>


                    <Persist name="contact-form" />


                </Form>
            )}
        </Formik>
    </div>;
};

export default FormikForm;
