import React from 'react';
import Layout from '../components/layout'
import FormikForm from '../components/forms/FormikForm';

const form = () => {
    return <Layout>
        <FormikForm />
    </Layout>;
};

export default form;
